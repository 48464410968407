import React, { Component } from 'react';
import Form from './form';

import ss from './imgs/sunflower-sunset.jpg';
import pt from './imgs/portrait.jpg';
import './app.scss';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      daysTil: undefined,
      hoursTil: undefined,
      minutesTil: undefined,
      secondsTil: undefined,
      daysPassed: undefined,
      hoursPassed: undefined,
      minutesPassed: undefined,
      secondsPassed: undefined,
    };

    this.weddingTime = new Date("Sep 21, 2019 17:15:00").getTime();
    this.togetherTime = new Date("Sep 18, 2012 15:30:00").getTime();
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick = () => {
    const now = new Date().getTime();
    const distance = this.weddingTime - now;
    const daysTil = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hoursTil = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesTil = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const secondsTil = Math.floor((distance % (1000 * 60)) / 1000);

    const passed = now - this.togetherTime;
    const daysPassed = Math.floor(passed / (1000 * 60 * 60 * 24));
    const hoursPassed = Math.floor((passed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesPassed = Math.floor((passed % (1000 * 60 * 60)) / (1000 * 60));
    const secondsPassed = Math.floor((passed % (1000 * 60)) / 1000);
    this.setState({
      daysTil: daysTil,
      hoursTil: hoursTil,
      minutesTil: minutesTil,
      secondsTil: secondsTil,
      daysPassed: daysPassed,
      hoursPassed: hoursPassed,
      minutesPassed: minutesPassed,
      secondsPassed: secondsPassed,
    });
  };

  render() {
    const {
      daysTil,
      hoursTil,
      minutesTil,
      secondsTil,
      daysPassed,
      hoursPassed,
      minutesPassed,
      secondsPassed,
    } = this.state;
    return (
      <div id="main">
        <div id="bg1" className="paralax">
          <div id="header" className="center">
            <div>
              <h1>Taylor & Taylor Gonzalez Wedding</h1>
              <h2>September 21, 2019</h2>
              <h2>5:15 PM</h2>
              <p>{daysTil}:{minutesTil}:{hoursTil}:{secondsTil}</p>
              <p>Until wedding</p>
            </div>
          </div>
        </div>
        <div className="section">
          <img src={ss} alt="Holding each other in the sunset" />
          <div className="center w-100">
            <h2>Helpful Information</h2>
            <h3>Where at?</h3>
            <p>Son Wray Ranch</p>
            <p>
              5420 NW 24th St
              Newton, Kansas 67114
            </p>
            <h3>Do you have gift registries?</h3>
            <p>
              Yes! We have <a
                href="https://www.amazon.com/wedding/share/taylorsquared"
                target="_blank"
              >Amazon</a> and <a
                href="https://www.target.com/gift-registry/gift/twotaylorswedding"
                target="_blank"
              >Target</a> registries!
            </p>
            <h3>We'll need a place to stay, do you have a block of hotel rooms reserved?</h3>
            <p>
              Yes! We have a block at The Holiday Inn in Newton. Just call <a href="tel:3168047040">(316) 804-7040</a> and
              tell them you would like to reserve a room for the Taylor Gonzalez and Taylor Burns wedding.
            </p>
            <h3>Directions to the wedding?</h3>
            <p>
              Here are directions to the wedding from Holiday Inn via <a
                href="https://www.google.com/maps/dir/holiday+inn+newton+ks/Son+Wray+Ranch,+Northwest+24th+Street,+Newton,+KS/@38.0563252,-97.4089769,13z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x87bb148cc1f84b3b:0xb70f6cecc601f175!2m2!1d-97.3204483!2d38.0515984!1m5!1m1!1s0x87bb15706184c41f:0x75361fcdf2c907f7!2m2!1d-97.4194705!2d38.0748701"
                target="_blank"
              >Google Maps.</a>
            </p>
          </div>
        </div>
        <div id="bg2" className="paralax parasm"/>
        <div className="section bottomless-shadow">
          <div className="center">
            <h2>Our History</h2>
            <p>
              The Taylors met at the G2 Athletics gym where Taylor Girl was training for softball and Taylor Boy
              was training for swimming. Their first date was to see the movie <i>The Possession</i>. They saw the movie twice
              just so they could spend more time together! They like to take walks together on the bike path along Sand Creek in Newton.
              For Taylor Girl's birthday, they walked along the trail where Taylor Boy had stashed flowers under a bench near the wooden bridges.
              He proposed to her after she discovered the flowers, and surprisingly she said yes!
            </p>
            <p>We have been dating for</p>
            <p>{daysPassed} Days,</p>
            <p>{hoursPassed} Hours,</p>
            <p>{minutesPassed} Minutes,</p>
            <p>{secondsPassed} Seconds</p>
          </div>
          <img src={pt} alt="Portrait of us" />
        </div>
        <footer>
          <Form />
        </footer>
      </div>
    );
  }
}

export default App;
