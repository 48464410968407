import React, { Component } from 'react';
import axios from 'axios';

import './form.scss';

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            rsvp: 'no',
            comments: '',
            submitted: false,
            additional1: '',
            error: false,
            loading: false,
        };

        this.doPost            = this.doPost.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleGuestChange = this.handleGuestChange.bind(this);
        this.handleSubmit      = this.handleSubmit.bind(this);
    }

    async doPost() {
        const prevState = this.state;
        const {name, rsvp, comments} = prevState;

        const additionals = [];
        for(let i = 1; `additional${i}` in prevState; i++) {
            if(/\S/.test(prevState[`additional${i}`]))
                additionals.push(prevState[`additional${i}`]);
            if(i === 1) {
                prevState[`additional${i}`] = '';
            } else {
                delete prevState[`additional${i}`];
            }
        }
        
        try {
            let res = await axios.post('/api/rsvp', {
                name: name,
                response: rsvp,
                comments: comments,
                hasPlusOne: (additionals.length > 0) ? "yes" : "no",
                plusOnes: additionals,
            });

            if(res.data.success) {
                prevState.submitted = true;
            } else {
                prevState.error = false;
            }
        } catch (e) {
            prevState.error = false;
        } finally {
            prevState.name = '';
            prevState.rsvp = false;
            prevState.comments = '';
            prevState.loading = false;
        }

        this.setState(prevState);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleGuestChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const nextState = this.state;
        const additional = 'additional';

        let num = parseInt(name.match(/[0-9]/)[0]);

        if(value === '') {
            while(additional + (num + 1) in nextState) {
                nextState[additional + num] = nextState[additional + (num + 1)];
                num++;
            }
            if(num !== 1)
                delete nextState[additional + num];
        } else {
            if(!(additional + (num + 1) in nextState)) {
                nextState[additional + (num + 1)] = '';
            }
            nextState[name] = value;
        }
        this.setState(nextState);
    }

    handleSubmit() {
        if(this.state.name === '')
            alert("Enter your name before you submit!")
        else
            this.setState({loading: true, submitted: true});
    }


    render() {
        const {name, rsvp, comments, submitted, error, loading} = this.state;
        const content = [];
        if(!submitted) {
            content.push(
                <label key="input_name">
                    <span>Your Name:</span>
                    <input 
                        name="name"
                        value={name}
                        type="text"
                        onChange={this.handleInputChange} />
                </label>,
                <div id="radio" key="input_rsvp">
                    <span>Will you be attending our wedding?</span>
                    <div>
                        <label>
                            <input
                                name="rsvp"
                                checked={rsvp === 'yes'}
                                type="radio"
                                value="yes"
                                onChange={this.handleInputChange} />
                            <span>Yes</span>
                        </label>
                        <label>
                            <input
                                name="rsvp"
                                checked={rsvp === 'no'}
                                type="radio"
                                value="no"
                                onChange={this.handleInputChange} />
                            <span>No</span>
                        </label>
                    </div>
                </div>
            );
            if(rsvp === 'yes') {
                let counter = 1;
                const additionals = [
                    <input 
                        key={'additional' + counter}
                        className="additional"
                        name={'additional' + counter}
                        type="text"
                        value={this.state['additional' + counter]}
                        onChange={this.handleGuestChange} />
                ];
                while ('additional' + ++counter in this.state) {
                    additionals.push(
                        <input
                            key={'additional' + counter}
                            className="additional"
                            name={'additional' + counter}
                            type="text"
                            value={this.state['additional' + counter]}
                            onChange={this.handleGuestChange} />
                    );
                }
                content.push(
                    <div key="guest_name">
                        <span id="accompanying-label">Others Accompanying You:</span>
                        <div id="accompanying">
                            {additionals}
                        </div>
                    </div>
                );
            }
            content.push(
                <label id="comments" key="input_comments">
                    <span>Any Questions, Comments, or Concerns?</span>
                    <textarea
                        name="comments" 
                        value={comments}
                        onChange={this.handleInputChange} />
                </label>,
                <input key="input_submit" onClick={this.handleSubmit} type="submit" value="Submit" />
            );
        } else {
            if (error)
                content.push(
                    <h1 id="confirmation" key="confirmation">Submission recieved, thank you!</h1>
                )
            else if (loading) {
                this.doPost();
                content.push(
                    <h1 id="confirmation" key="confirmation">Sending in your answers...</h1>
                )
            } else
                content.push(
                    <h1 id="confirmation" key="confirmation">Submission recieved, thank you!</h1>
                )
        }
        return (
            <div id="form">
                {content}
            </div>
        );
    }
}

export default Form;